import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('..\\store\\index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('..\\store\\customer.js'), 'customer.js')
  resolveStoreModules(require('..\\store\\rememberedUser.js'), 'rememberedUser.js')
  resolveStoreModules(require('..\\store\\shoppingCart.js'), 'shoppingCart.js')
  resolveStoreModules(require('..\\store\\shoppingCartCheckoutAddresses.js'), 'shoppingCartCheckoutAddresses.js')
  resolveStoreModules(require('..\\store\\wishList.js'), 'wishList.js')
  resolveStoreModules(require('..\\store\\backoffice\\catalog.js'), 'backoffice/catalog.js')
  resolveStoreModules(require('..\\store\\backoffice\\closedPriceQuote.js'), 'backoffice/closedPriceQuote.js')
  resolveStoreModules(require('..\\store\\backoffice\\customOrder.js'), 'backoffice/customOrder.js')
  resolveStoreModules(require('..\\store\\backoffice\\estimate.js'), 'backoffice/estimate.js')
  resolveStoreModules(require('..\\store\\backoffice\\invoices.js'), 'backoffice/invoices.js')
  resolveStoreModules(require('..\\store\\backoffice\\jobOrders.js'), 'backoffice/jobOrders.js')
  resolveStoreModules(require('..\\store\\backoffice\\openPriceQuote.js'), 'backoffice/openPriceQuote.js')
  resolveStoreModules(require('..\\store\\backoffice\\priceQuote.js'), 'backoffice/priceQuote.js')
  resolveStoreModules(require('..\\store\\backoffice\\products.js'), 'backoffice/products.js')
  resolveStoreModules(require('..\\store\\backoffice\\settings.js'), 'backoffice/settings.js')
  resolveStoreModules(require('..\\store\\common\\cache.js'), 'common/cache.js')
  resolveStoreModules(require('..\\store\\common\\companyDetails.js'), 'common/companyDetails.js')
  resolveStoreModules(require('..\\store\\common\\decedent.js'), 'common/decedent.js')
  resolveStoreModules(require('..\\store\\common\\entityStatus.js'), 'common/entityStatus.js')
  resolveStoreModules(require('..\\store\\common\\error.js'), 'common/error.js')
  resolveStoreModules(require('..\\store\\common\\formatter.js'), 'common/formatter.js')
  resolveStoreModules(require('..\\store\\common\\gridSetting.js'), 'common/gridSetting.js')
  resolveStoreModules(require('..\\store\\common\\hardcodedValue.js'), 'common/hardcodedValue.js')
  resolveStoreModules(require('..\\store\\common\\itemType.js'), 'common/itemType.js')
  resolveStoreModules(require('..\\store\\common\\roleType.js'), 'common/roleType.js')
  resolveStoreModules(require('..\\store\\common\\shippingMethod.js'), 'common/shippingMethod.js')
  resolveStoreModules(require('..\\store\\common\\switchUser.js'), 'common/switchUser.js')
  resolveStoreModules(require('..\\store\\common\\userProfile.js'), 'common/userProfile.js')
  resolveStoreModules(require('..\\store\\backoffice\\cms\\assetLimitation.js'), 'backoffice/cms/assetLimitation.js')
  resolveStoreModules(require('..\\store\\backoffice\\cms\\assets.js'), 'backoffice/cms/assets.js')
  resolveStoreModules(require('..\\store\\backoffice\\cms\\components.js'), 'backoffice/cms/components.js')
  resolveStoreModules(require('..\\store\\backoffice\\cms\\contents.js'), 'backoffice/cms/contents.js')
  resolveStoreModules(require('..\\store\\backoffice\\cms\\datasources.js'), 'backoffice/cms/datasources.js')
  resolveStoreModules(require('..\\store\\backoffice\\cms\\fields.js'), 'backoffice/cms/fields.js')
  resolveStoreModules(require('..\\store\\backoffice\\configuration\\customerOrderSettings.js'), 'backoffice/configuration/customerOrderSettings.js')
  resolveStoreModules(require('..\\store\\backoffice\\configuration\\globalSettings.js'), 'backoffice/configuration/globalSettings.js')
  resolveStoreModules(require('..\\store\\backoffice\\configuration\\modules.js'), 'backoffice/configuration/modules.js')
  resolveStoreModules(require('..\\store\\backoffice\\configuration\\workflows.js'), 'backoffice/configuration/workflows.js')
  resolveStoreModules(require('..\\store\\backoffice\\dashboard\\dashboard.js'), 'backoffice/dashboard/dashboard.js')
  resolveStoreModules(require('..\\store\\backoffice\\financial\\customerCredit.js'), 'backoffice/financial/customerCredit.js')
  resolveStoreModules(require('..\\store\\backoffice\\payments\\creditCard.js'), 'backoffice/payments/creditCard.js')
  resolveStoreModules(require('..\\store\\backoffice\\payments\\payment.js'), 'backoffice/payments/payment.js')
  resolveStoreModules(require('..\\store\\backoffice\\product-catalog\\productCatalog.js'), 'backoffice/product-catalog/productCatalog.js')
  resolveStoreModules(require('..\\store\\backoffice\\status-tracking\\salesOrder.js'), 'backoffice/status-tracking/salesOrder.js')
  resolveStoreModules(require('..\\store\\common\\crm\\contactPerson.js'), 'common/crm/contactPerson.js')
  resolveStoreModules(require('..\\store\\common\\crm\\customerType.js'), 'common/crm/customerType.js')
  resolveStoreModules(require('..\\store\\common\\crm\\territorialUnit.js'), 'common/crm/territorialUnit.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
